@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.root {
  .mapContainer {
    position: relative;
    height: 240px;

    .mapHeader {
      left: 0;
      bottom: 0;
      height: 60px;
      width: 100%;
      color: #ffffff;
      background: color.change($mn-dark-bg, $alpha: 0.7);
      position: absolute;
    }
  }

  table thead th {
    background-color: white !important;
    color: var(--bs-primary) !important;
    text-transform: uppercase;
  }

  table tbody td {
    vertical-align: middle;
  }

  table tbody td.textAlignCenter {
    text-align: center;
  }

  table tbody tr {
    line-height: 34px;
  }

  table tbody td[aria-label="row-header"] {
    background-color: #b7b7b7 !important;
    color: #ffffff;
    pointer-events: none;
  }

  .actionButton {
    min-width: 110px;
    display: none;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: $spacer;
    transform: translate(0%, -50%);
    white-space: nowrap;
  }

  tr:hover .actionButton {
    display: block;
  }

  .dangerLink {
    a {
      color: var(--bs-alert-color) !important;
      font-weight: 700;
    }
  }

}
