@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
.eventSignalIcon {
  display: block;
  width: 28px;
  height: 16px;
  display: inline-block;

  &.eventSignalOff {
    background: url(~assets/svg/signal-off.svg) no-repeat center center;
  }

  &.eventSignal0 {
    background: url(~assets/svg/signal-1.svg) no-repeat center center;
  }

  &.eventSignal1 {
    background: url(~assets/svg/signal-2.svg) no-repeat center center;
  }

  &.eventSignal2 {
    background: url(~assets/svg/signal-3.svg) no-repeat center center;
  }

  &.eventSignal3 {
    background: url(~assets/svg/signal-4.svg) no-repeat center center;
  }
}

.eventPriorityIcon {
  height: 12px;
  width: 12px;

  &.eventPriorityNormal {
    background: url(~assets/svg/dot-green.svg) no-repeat center center;
  }

  &.eventPriorityAlarm {
    background: url(~assets/svg/dot-orange.svg) no-repeat center center;
  }

  &.eventPriorityHigh {
    background: url(~assets/svg/dot-orange-stroke.svg) no-repeat center center;
  }
}

.eventBatteryIcon {
  width: 28px;
  height: 16px;
  display: inline-block;

  &.eventBatteryOff {
    background: url(~assets/svg/battery-off.svg) no-repeat center center;
  }

  &.eventBattery0 {
    background: url(~assets/svg/battery-0.svg) no-repeat center center;
  }

  &.eventBattery1 {
    background: url(~assets/svg/battery-1.svg) no-repeat center center;
  }

  &.eventBattery2 {
    background: url(~assets/svg/battery-2.svg) no-repeat center center;
  }

  &.eventBattery3 {
    background: url(~assets/svg/battery-3.svg) no-repeat center center;
  }
}
