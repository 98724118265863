@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.switchOnOffContainer {
  display: table;
  width: 100%;
}

.switchOnOffLabel {
  display: table-cell;
}

.switchOnOffCheck {
  display: table-cell;
  width: 100;
  text-align: right;
}
