@import "~bootstrap/scss/functions";

$primary: $mn-color-asparagus;
$secondary: $mn-color-cultured;
$light: $mn-color-cultured;

$gray-200: $mn-color-cultured;

$mn-border-radius: 0.25rem;

$font-family-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial,
  "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";

$heat-map-heat-0: #a1ba89;

$tooltip-max-width: 400px;

$mp_logo_path: "~#{$brand}/assets/svg/logo.svg";
$mp_smalllogo_path: "~#{$brand}/assets/svg/small_logo.svg";
$mp_alerthouselogo_path: "~#{$brand}/assets/svg/alerthouse_logo.svg";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.gap-row-1 {
  row-gap: $spacer * 0.25;
}

.gap-row-2 {
  row-gap: $spacer * 0.5;
}

.gap-row-3 {
  row-gap: $spacer;
}

.gap-col-1 {
  column-gap: $spacer * 0.25;
}

.gap-col-2 {
  column-gap: $spacer * 0.5;
}

.gap-col-3 {
  column-gap: $spacer;
}

.btn-primary {
  color: white !important;
}

.title {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-sm {
  font-size: $font-size-sm;
}

.w-min {
  min-width: 80px;
}
