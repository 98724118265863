@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.item {
  width: 100%;
  height: 50px;
  padding: 0px 8px 0px 16px;

  display: flex;
  align-items: center;
  justify-content: start;

  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  .icon {
    width: 20px;
  }

  .text {
    font-size: 16px;
    word-wrap: break-word;
    width: 250px;
  }

  &.active {
    background-color: var(--bs-primary);
  }

  .location {
    background: url(~assets/svg/arrow_drop_on_map_gray.svg) no-repeat center center;
    background-size: contain;
    width: 20px;
    height: 25px;

    &.active {
      background: url(~assets/svg/arrow_drop_on_map_active.svg) no-repeat center center;
      cursor: pointer;
    }
  }
}