@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tabsContainer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    border-radius: $mn-border-radius;
    padding: 5px;
    background: #f2f5f8;

    & a {
      text-align: center;
      cursor: pointer;
    }
  }
}

.pane {
  background: var(--bs-secondary);
  border-radius: $mn-border-radius;
  padding: $spacer;
}