@use "sass:color";

$mn-color-rifle-green: #48533d;
$mn-color-cream: #fdfdcb;
$mn-color-russian-green: #7a8d68;
$mn-color-asparagus: #89af6c;
$mn-color-cultured: #f2f5f8;
$mn-color-light-gray: #d0d9e1;
$mn-color-gunmetal: #202c3a;

$mn-dark-bg: $mn-color-rifle-green;
$mn-dark-color: $mn-color-russian-green;