@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
:global .react-tel-input {
  .form-control {
    width: 100%;
    height: inherit;
  }
}

:global .input-group:not(.has-validation) > .react-tel-input:not(:last-child) {
  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
