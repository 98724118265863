@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.locationInfo {
  position: absolute;
  right: 90px;
  top: 20px;
  border-radius: $mn-border-radius;
  background: color.change($mn-dark-bg, $alpha: 0.7);
  padding: $spacer;
  color: white;
}
