@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.root {
  min-height: 98px;
  background: $mn-dark-bg;
  position: relative;

  .logo {
    background: url($mp_logo_path) no-repeat center center;
    background-size: contain;
    width: 110px;
    height: 92px !important;
  }

  & :global(.nav-pills .nav-link) {
    border-radius: 0px !important;
  }

  & :global(.navbar-nav .nav-link:not(.active)) {
    color: $mn-color-asparagus !important;
  }

  .userName {
    max-width: 15rem;
    color: white !important;
  }

  .progress {
    width: 100%;
    height: 5px !important;
    position: absolute;
    z-index: 10;
  }
}
