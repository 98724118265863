@use "sass:color";@import "brands/minkpolice/assets/styles/brand.scss";$brand:minkpolice;
@import "~assets/styles/variables.scss";

.root {
  min-height: 500px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: $spacer;

  .right {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: $spacer;
  }
}
